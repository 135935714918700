import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MkInfoCard, MkSubBody } from 'graphql/gql/wrapper/graphql'

type ExploreItemContentProps = Pick<MkInfoCard, 'exploreItemContentMaxWidth' | 'mk_position'>
type ExploreItemProps = {
  isHovered?: boolean
}

export const ExploreItemImage = styled.img`
  transition: opacity 0.3s ease 0s;
  width: 100%;
  height: 100%;
  display: block;
`

export const ExploreItem = styled.div<ExploreItemProps>`
  position: relative;
  width: 100%;

  ${({ isHovered }) =>
    isHovered &&
    `
    cursor: pointer;
    &:hover a {
      text-decoration: underline;
    }
  `}
`

export const ExploreItemInner = styled.div`
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`

export const ExploreItemContent = styled.div<ExploreItemContentProps>`
  flex-flow: column;
  padding: 10px;
  display: flex;
  position: ${(props) => props.mk_position || 'absolute'};
  justify-content: center;
  top: 0;
  left: 1.5rem;
  max-width: ${(props) => props.exploreItemContentMaxWidth || '50%'};
  height: 100%;
`

type OverlayProps = {
  overlayColor: string
  overlayOpacity: number
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => props.overlayOpacity};
  background-color: ${(props) => props.overlayColor};
`

export const ExploreLink = styled.a`
  display: flex;
  flex-grow: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  cursor: pointer;
`
