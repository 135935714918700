import {MkSubCtaHtp } from "graphql/gql/wrapper/graphql";
import React from "react";

type  MkSubHowToPlayCtaProps = {
  mk_sub_cta_htp: MkSubCtaHtp
}
import { StyledHeading, StyledImg, StyledLink } from './styled/MkSubHowToPlayCta.styled'

const MkSubHowToPlayCta: React.FC<MkSubHowToPlayCtaProps> = ({ mk_sub_cta_htp }) => {
  return (
    <StyledHeading>
      <StyledLink>{mk_sub_cta_htp?.mk_callToAction_text}</StyledLink>
      <StyledImg src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/arrow-right.svg" />
    </StyledHeading>
  )
}

export default MkSubHowToPlayCta;