import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'

export const StyledHeading = styled.h3`
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  color: rgb(255, 255, 255);
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  margin-bottom: 10px;
`
export const StyledImg = styled.img`
  margin: 0px;
  padding: 0px;

  border-style: none;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  width: 20px;
  height: auto;
  margin-left: 5px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`
