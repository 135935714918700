import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ExploreItemHeadingStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${mediaEmotion.lg(css`
    margin-bottom: 18px;
  `)}
`

export const Badge = styled.img`
  margin: 0 8px 0 0;
  vertical-align: middle;
  transition: opacity 0.3s ease;
  opacity: 1;
  display: inline-block;
  width: 18px;
  height: auto;

  ${mediaEmotion.sm(css`
    margin: 0 8px 0 0;
    border: none;
    vertical-align: middle;
    max-width: 100%;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    display: inline-block;
    height: auto;
  `)}

  ${mediaEmotion.lg(css`
    margin: 0 8px 0 0;
    border: none;
    vertical-align: middle;
    max-width: 100%;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    display: inline-block;
    width: 24px;
    height: auto;
  `)}
`

export const Header = styled.img`
  transition: opacity 0.3s ease;
  width: auto;
  height: 16px;

  ${mediaEmotion.sm(css`
    height: 10px;
  `)}

  ${mediaEmotion.lg(css`
    height: 16px;
  `)}
`
