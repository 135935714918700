import styled from '@emotion/styled'
import Image from 'next/image'
import 'swiper/css'
import 'swiper/css/navigation'
import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import { proximaNova, knockout } from '@/utils/fonts'

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  user-select: none; // Prevents slides from sometimes becoming highlighted on slide nav

  & p {
    font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
    line-height: unset; // temp override of app.css styles
    margin-bottom: initial; // temp override of app.css styles
  }
`

export const SlideBackgroundImage = styled(Image)`
  position: absolute;
  object-fit: cover; // Ensures image fills container
  max-width: initial; // temp override of app.css styles
  height: -webkit-fill-available; // temp override of app.css styles
`

export const ContentContainer = styled.div`
  position: relative;
  box-sizing: border-box; // otherwise it overflows parent element
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 5;
  gap: 20px;
  padding: 20px 20px 34px;

  ${mediaEmotion.sm(css`
    justify-content: center;
    width: 1200px;
    gap: 25px;
  `)}
`

export const ProductNameAndLogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 496px;
  max-height: 40px;

  ${mediaEmotion.sm(css`
    gap: 6px;
  `)}

  ${mediaEmotion.lg(css`
    gap: 12px;
  `)}
`

export const ProductNameAndLogoImage = styled(Image)`
  height: 26px;
  width: max-content;

  ${mediaEmotion.sm(css`
    height: 31px;
  `)}

  ${mediaEmotion.lg(css`
    height: 40px;
  `)};
`

export const HeadlineImage = styled.img`
  width: fit-content;
  max-width: 334px;

  ${mediaEmotion.sm(css`
    max-width: 350px;
  `)}

  ${mediaEmotion.lg(css`
    max-width: 404px;
  `)}
`

export const HeadlineText = styled.span`
  font-family: ${knockout}, sans-serif;
  font-weight: 69;
  font-size: 36px;
  color: white;

  ${mediaEmotion.sm(css`
    max-width: 280px;
  `)}

  ${mediaEmotion.lg(css`
    max-width: 400px;
    font-size: 46px;
  `)}
`

export const SublineContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: white;

  ${mediaEmotion.sm(css`
    max-width: 280px;
  `)}

  ${mediaEmotion.lg(css`
    max-width: 400px;
    font-size: 18px;
    line-height: 22px;
  `)}
`

export const SublineHeading = styled.p`
  font-weight: bold;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaEmotion.sm(css`
    flex-direction: row;
  `)}
`

export const StyledBaseButton = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 1.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid white;
  border-radius: 4px;
  padding: 12px 16px;

  ${mediaEmotion.sm(css`
    width: fit-content;
  `)}

  &:hover {
    transform: scale(1.1);
  }
`

export const StyledButtonOne = styled(StyledBaseButton)`
  color: white;
  background-color: transparent;
  border: 1px solid white;
`

export const StyledButtonTwo = styled(StyledBaseButton)`
  color: #005fc8;
  background-color: white;
  border: none;
`

export const BottomNav = styled.div`
  position: absolute; // Force nav to bottom of the carousel on mobile
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  button {
    display: flex;
    cursor: pointer;
    background: none;
    border: none;
    padding-top: 0;
  }

  ${mediaEmotion.sm(css`
    position: initial;
    justify-content: flex-start;
  `)}
`
