import { MkSubHeader as MkSubHeaderGQL } from 'graphql/gql/wrapper/graphql';
import React from 'react'
import { Badge, ExploreItemHeadingStyle, Header } from './styled/MkSubHeader.styled'

type  MkSubHeaderProps = {
  mk_subHeader: MkSubHeaderGQL;
}

const MkSubHeader: React.FC<MkSubHeaderProps> = ({ mk_subHeader }) => {
  return (
    <ExploreItemHeadingStyle>
      <div className="explore-item-heading" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Badge src={mk_subHeader?.mk_logoHeaderImage?.asset?.url ?? ''} />
        <Header src={mk_subHeader?.mk_HeaderImage?.asset?.url ?? ''} />
      </div>
    </ExploreItemHeadingStyle>
  );
};

export default MkSubHeader;
