import { request } from 'graphql-request';
import getMenusQuery from 'graphql/queries/orch/getMenus.orch';
import getHomepage from 'graphql/queries/wrapper/getHomepage.wrapper';
import getPage from 'graphql/queries/wrapper/getPage.wrapper';
import getPages from 'graphql/queries/wrapper/getPages.wrapper';
import getRewrites from 'graphql/queries/wrapper/getRewrites.wrapper';

const getWrapperEndpoint = () => {
  const NEXT_PUBLIC_ENDPOINT = process.env.NEXT_PUBLIC_ENDPOINT;
  return NEXT_PUBLIC_ENDPOINT || 'http://localhost:8080/graphql';
};

const getOrchEndpoint = () => {
  const NEXT_PUBLIC_ORCH_ENDPOINT = process.env.NEXT_PUBLIC_ORCH_ENDPOINT;
  return NEXT_PUBLIC_ORCH_ENDPOINT || ''
};

export function getMenus(isPreview?: boolean) {
  return request(
    getOrchEndpoint(),
    getMenusQuery,
    { isPreview },
  );
}

export function getPagesApi(limit: number, offset: number) {
  return request(
    getWrapperEndpoint(),
    getPages,
    { limit, offset, },
  );
}

export function getRewritesApi(limit: number, offset: number) {
  return request(
    getWrapperEndpoint(),
    getRewrites,
    { limit, offset, },
  );
}

export function getPageApi(mk_slug: string) {
  return request(
    getWrapperEndpoint(),
    getPage,
    { mk_slug },
  );
}

export function getHomepageApi() {
  return request(
    getWrapperEndpoint(),
    getHomepage
  );
}


export default {};
